import React from "react";
import styles from "./styles";
import Message from "../Message";
import messageStyle from "../Message/styles";
import ScrollToBottom from "react-scroll-to-bottom";
import { LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";

class Conversation extends React.Component {
  state = {
    currentNextUser: null,
    alreadyFetched: false
  };

  componentDidMount() {
    const { selectedUser } = this.props;
    if (selectedUser) {
      this.setState({ currentNextUser: selectedUser });
    }
  }

  componentDidUpdate() {
    const {
      selectedUser,
      fetchMessages,
      isUsersFetched,
      isMessagesFetched
    } = this.props;

    // Get messages when nextUser is changed
    if (isUsersFetched && this.state.currentNextUser !== selectedUser) {
      this.setState({
        currentNextUser: selectedUser
      });
      fetchMessages(selectedUser);
    }
    // Get messages after users are fetched
    if (isUsersFetched && !isMessagesFetched && !this.state.alreadyFetched) {
      this.setState({ alreadyFetched: true });
      fetchMessages(selectedUser);
    }
  }

  render() {
    const { messages, user, nextUser } = this.props;
    if (messages && nextUser) {
      return (
        <ScrollToBottom
          className={styles.main}
          scrollViewClassName={styles.messagesContainer}
        >
          {messages.map((message, index) =>
            message.from_user !== user.username ? (
              <Message
                message={message}
                messageType={messageStyle.containerLeft}
                messageSide={messageStyle.messageLeft}
                key={index}
                userAvatar={nextUser.avatar}
              />
            ) : (
              <Message
                message={message}
                messageType={messageStyle.containerRight}
                messageSide={messageStyle.messageRight}
                key={index}
                userAvatar={user.avatar}
              />
            )
          )}
        </ScrollToBottom>
      );
    }
    return (
      <div className={styles.main}>
        <LinearProgress variant="query" />
      </div>
    );
  }
}

Conversation.propTypes = {
  user: PropTypes.object,
  messages: PropTypes.array,
  nextUser: PropTypes.object,
  selectedUser: PropTypes.string,
  fetchMessages: PropTypes.func,
  isMessagesFetched: PropTypes.bool,
  isUsersFetched: PropTypes.bool
};

export default Conversation;
