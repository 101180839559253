import React from "react";

class Settings extends React.Component {
  render() {
    return (
      // TODO 
      <div>
        <div />
      </div>
    );
  }
}

export default Settings;
