import React from "react";
import localStyles from "./styles";
import Conversation from "../../components/Conversation";
import { Field, reduxForm } from "redux-form";
import Dropzone from "react-dropzone";
import SendIcon from "@material-ui/icons/Send";
import Fab from "@material-ui/core/Fab";
import EmojiHolder from "../../components/EmojiHolder";
import SmileIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { connect } from "react-redux";
import { onEmojiPicker } from "../../../../redux/chatControlers/actions";
import { sendNotification } from "../../../../redux/notifications/thunks";
import {
  addEmoji,
  resetNewMessageNumber,
  fetchMessages
} from "../../../../redux/messages/thunks";
import { onUserSelect } from "../../../../redux/messages/actions";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { Grid, Tooltip } from "@material-ui/core";
import CustomAvatar from "../../components/CustomAvatar";

const renderField = ({ input, handleSubmit, resetNewMessageNumber }) => (
  <textarea
    {...input}
    autoComplete="off"
    className={localStyles.inputMessage}
    cols="100"
    placeholder="Your message..."
    onKeyPress={e => {
      const code = e.keyCode ? e.keyCode : e.which;
      if (code === 13) {
        handleSubmit();
      }
    }}
    onFocus={resetNewMessageNumber()}
  />
);

renderField.propTypes = {
  input: PropTypes.object,
  handleSubmit: PropTypes.func,
  resetNewMessageNumber: PropTypes.func
};

const capitalize = s => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

let ChatMain = class ChatMain extends React.Component {
  state = {
    userId: this.props.nextUser.id
  };

  setUserId() {
    let selectedUser = this.props.match.params.id;

    if (this.state.userId !== selectedUser) {
      this.setState({ userId: selectedUser });
    }
  }
  componentDidMount() {
    this.setUserId();
  }

  componentDidUpdate() {
    this.setUserId();
  }

  componentWillUnmount() {
    this.props.onUserSelect({});
  }

  render() {
    let props = this.props;
    let selectedUser = this.state.userId;

    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
        className={localStyles.container}
      >
        <Grid item className={localStyles.upper}>
          <div className={localStyles.header}>
            {!isEmpty(props.nextUser) ? (
              <div className={localStyles.userInfo}>
                <CustomAvatar
                  user={props.nextUser}
                  onlineUsers={props.onlineUsers}
                  dotted
                />
                <div className={localStyles.text}>
                  <span className={localStyles.userHeader}>
                    {capitalize(props.nextUser.firstname)}{" "}
                    {capitalize(props.nextUser.lastname)}
                  </span>
                  <span>Pseudo: #{props.nextUser.pseudo}</span>
                </div>
              </div>
            ) : (
              <div className={localStyles.loading} />
            )}
          </div>
          <div className={localStyles.chatingComponent}>
            <Conversation
              nextUser={props.nextUser}
              messages={props.usersMessages}
              user={props.user}
              selectedUser={selectedUser}
              fetchMessages={props.fetchMessages}
              isUsersFetched={props.isFetched}
              isMessagesFetched={props.isMessagesFetched}
            />
          </div>
        </Grid>

        <Grid
          container
          component="div"
          direction="column"
          justify="flex-end"
          alignItems="flex-start"
          className={localStyles.footer}
        >
          <EmojiHolder
            showEmojiPicker={props.showEmojiPicker}
            addEmoji={props.addEmoji}
            onEmojiPicker={props.onEmojiPicker}
          />

          <form onSubmit={props.handleSubmit} className={localStyles.myInput}>
            <Tooltip title="Add emoji" placement="bottom" arrow>
              <SmileIcon
                onClick={() => props.onEmojiPicker(true)}
                className={localStyles.emoji}
              />
            </Tooltip>

            <Field
              name="photo"
              component={() => (
                <Dropzone>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Tooltip title="Add attachment" placement="bottom" arrow>
                        <AttachmentIcon className={localStyles.attachment} />
                      </Tooltip>
                    </div>
                  )}
                </Dropzone>
              )}
            />
            <Field
              name="message"
              handleSubmit={props.handleSubmit}
              component={renderField}
              resetNewMessageNumber={props.resetNewMessageNumber}
            />
            <Fab color="primary" aria-label="Add" type="submit">
              <SendIcon />
            </Fab>
          </form>
        </Grid>
      </Grid>
    );
  }
};

ChatMain.propTypes = {
  match: PropTypes.object,
  nextUser: PropTypes.object,
  user: PropTypes.object,
  onlineUsers: PropTypes.object,
  usersMessages: PropTypes.array,
  isFetched: PropTypes.bool,
  isMessagesFetched: PropTypes.bool,
  showEmojiPicker: PropTypes.bool,
  handleSubmit: PropTypes.func,
  sendNotification: PropTypes.func,
  onEmojiPicker: PropTypes.func,
  addEmoji: PropTypes.func,
  resetNewMessageNumber: PropTypes.func,
  fetchMessages: PropTypes.func,
  sendMessage: PropTypes.func,
  onSubmit: PropTypes.func,
  onUserSelect: PropTypes.func
};

const mapState = ({
  users: { users, onlineUsers, isFetched },
  messages: { usersMessages, nextUser, isMessageSent, isMessagesFetched },
  auth: { user },
  notifications: { requestResult },
  chatControlers: { showEmojiPicker }
}) => ({
  users,
  onlineUsers,
  usersMessages,
  nextUser,
  isMessageSent,
  user,
  isFetched,
  isMessagesFetched,
  requestResult,
  showEmojiPicker
});

const mapDispatch = {
  sendNotification,
  onUserSelect,
  onEmojiPicker,
  addEmoji,
  resetNewMessageNumber,
  fetchMessages
};

ChatMain = connect(mapState, mapDispatch)(ChatMain);

export default reduxForm({ form: "message" })(ChatMain);
