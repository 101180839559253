import React from "react";
import styles from "./styles";
import moment from "moment";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";

const Message = ({ message, messageType, messageSide, userAvatar }) => (
  <div className={messageType}>
    {message.show_avatar ? (
      <img className={styles.image} src={userAvatar} alt="user" />
    ) : (
      <div className={styles.noAvatar} />
    )}
    <div className={styles.messageContainer}>
      <div className={messageSide}>{message.subject}</div>
      {message.sent && (
        <div className={styles.time}>
          {moment(message.date).format("HH:mm")}
          <Icon className={styles.done}>done_all</Icon>
        </div>
      )}
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.object,
  messageType: PropTypes.string,
  messageSide: PropTypes.string,
  userAvatar: PropTypes.string
};

export default Message;
