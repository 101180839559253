import React from "react";
import styles from "./styles";
import { connect } from "react-redux";
import { onSearchChange } from "../../../../redux/users/actions";
import { fetchUsers } from "../../../../redux/users/thunks";
import Search from "../../components/Search";
import LinearProgress from "@material-ui/core/LinearProgress";
import SideNavUser from "../../components/SideNavUser";
import PropTypes from "prop-types";
import AddFriendDialog from "../ChatMain/AddFriendDialog";
import { sendNotification } from "../../../../redux/notifications/thunks";

// High Order Function
const matchUser = searchQuery => user => {
  const fullName = user["firstname"] + " " + user["lastname"];
  return fullName.toLowerCase().includes(searchQuery.toLowerCase());
};

class Users extends React.Component {
  authedUser = this.props.user.id;

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers();
  }

  setOnSearch = event => {
    this.props.onSearchChange(event.target.value || "");
  };

  render() {
    const {
      users,
      search,
      onlineUsers,
      selectedUser,
      isLastMessagesFetched,
      sendNotification,
      requestResult
    } = this.props;
    return !isLastMessagesFetched ? (
      <LinearProgress variant="query" className={styles.loader} />
    ) : (
      <React.Fragment>
        <div className={styles.searchContainer}>
          <Search value={search} onChange={this.setOnSearch} />
          <AddFriendDialog
            onSubmit={sendNotification}
            notificationResult={requestResult}
          />
        </div>

        <div className={styles.users}>
          {users.map((user, index) => (
            <SideNavUser
              key={index}
              user={user}
              selectedUser={selectedUser}
              numberOfNewMessages={user.numberOfNewMessages}
              lastMessage={user.lastMessage}
              onlineUsers={onlineUsers}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  search: PropTypes.string,
  onlineUsers: PropTypes.object,
  selectedUser: PropTypes.number,
  onSearchChange: PropTypes.func,
  fetchUsers: PropTypes.func,
  sendNotification: PropTypes.func,
  requestResult: PropTypes.string,
  isLastMessagesFetched: PropTypes.bool
};

const mapState = ({
  auth: { user },
  users: { users, search, onlineUsers, ...props },
  notifications: { requestResult }
}) => ({
  ...props,
  user,
  search,
  requestResult,
  users: Object.values(users).filter(matchUser(search)),
  onlineUsers
});

const mapDispatch = {
  fetchUsers,
  onSearchChange,
  sendNotification
};

export default connect(mapState, mapDispatch)(Users);
