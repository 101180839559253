const initialState = {
  showEmojiPicker: false,
  reloadChatApp: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "showEmojiPicker":
      return { ...state, showEmojiPicker: action.value };
    case "resetInitialState":
      return { ...state, reloadChatApp: true };
    default:
      return state;
  }
}
