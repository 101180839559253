import React from "react";
import { Grid, CssBaseline, Badge, Icon, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CustomAvatar from "../../components/CustomAvatar";
import { NavLink } from "react-router-dom";
import { logout } from "../../../../redux/auth/thunks";
import styles from "./styles";

const SideNavigation = ({ user, onlineUsers, invitationsNumber, logout }) => {
  const items = [
    {
      icon: "message",
      title: "Messages",
      link: "/messaging/users",
      badgeContent: 0
    },
    {
      icon: "person",
      title: "Invitations",
      link: "/messaging/invitations",
      badgeContent: invitationsNumber
    }
  ];
  return (
    <Grid
      container
      direction="column"
      justify="space-between"
      alignItems="center"
      className={styles.container}
    >
      <Grid
        container
        component="div"
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <CssBaseline />
        <Grid
          container
          alignItems="center"
          direction="column"
          className={styles.avatar}
        >
          <CustomAvatar user={user} onlineUsers={onlineUsers} dotted />
        </Grid>

        {/* Icons section */}
        {items.map((item, index) => (
          <Tooltip key={index} title={item.title} placement="right" arrow>
            <Grid
              container
              alignItems="center"
              direction="column"
              component={NavLink}
              to={item.link}
              className={styles.icon}
              activeClassName={styles.selected}
            >
              <Badge badgeContent={item.badgeContent} color="secondary">
                <Icon style={{ fontSize: 30 }}>{item.icon}</Icon>
              </Badge>
            </Grid>
          </Tooltip>
        ))}
      </Grid>
      {/* Logout section */}
      <Grid
        container
        component="div"
        direction="column"
        justify="flex-end"
        alignItems="center"
        className={styles.logoutSection}
      >
        <Tooltip title="Logout" placement="right" arrow>
          <Icon
            className={styles.logoutIcon}
            style={{ fontSize: 30 }}
            onClick={() => {
              logout();
            }}
          >
            logout
          </Icon>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const mapState = ({
  auth: { user, ...props },
  users: { onlineUsers },
  notifications: { invitationsNumber }
}) => ({
  ...props,
  user,
  onlineUsers,
  invitationsNumber
});

const mapDispatch = {
  logout
};

SideNavigation.propTypes = {
  user: PropTypes.object,
  onlineUsers: PropTypes.object,
  invitationsNumber: PropTypes.number,
  logout: PropTypes.func
};
export default connect(mapState, mapDispatch)(SideNavigation);
