import * as actions from "./actions";

import axios from "axios";

export const sendNotification = ({ pseudoFriend }) => (dispatch, getState) => {
  const { auth, users } = getState();

  let alreadyFriend = false;

  Object.values(users.users).map(user => {
    if (user.pseudo === pseudoFriend) {
      dispatch(
        actions.getNotificationRequestMessage(
          `Already Friend with ${pseudoFriend}`
        )
      );
      return (alreadyFriend = true);
    }
  });

  if (auth.user.pseudo === pseudoFriend) {
    dispatch(
      actions.getNotificationRequestMessage(`You can not invite your self !!`)
    );
    return (alreadyFriend = true);
  }
  if (!alreadyFriend) {
    axios
      .post("/users/notifications/send", { toUser: pseudoFriend })
      .then(response => response.data)
      .then(response => {
        dispatch(actions.getNotificationRequestMessage(response.message));
      });
  }
};

export const confirmInvitation = pseudoFriend => {
  axios.put("/users/notifications/invite", { pseudoFriend });
};

export const updateNotification = notificationId => (dispatch, getState) => {
  const { notifications } = getState();

  let notifs = notifications.notifications.map(notif => {
    if (notif.id === notificationId) {
      notif.invitationAccepted = true;
    }
    return notif;
  });

  dispatch(actions.setNotifications(notifs));
};
