import React from "react";
import { withStyles, makeStyles } from "@material-ui/styles";
import { Badge, Box, Avatar } from "@material-ui/core";
import get from "lodash/get";
import PropTypes from "prop-types";

const StyledBadge = withStyles(theme => ({
  badge: {
    height: "8px",
    width: "8px",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%"
    }
  },
  dot: {
    backgroundColor: "#44b700"
  }
}))(Badge);

const useStyles = makeStyles({
  box: {
    margin: "0"
  },
  error: {
    backgroundColor: "red"
  },
  success: {
    backgroundColor: "#44b700"
  },
  avatar: {
    width: 50,
    height: 50
  }
});
const CustomAvatar = ({ user, onlineUsers, dotted }) => {
  const classes = useStyles();

  return (
    <div>
      {dotted ? (
        <Box m={1} className={classes.box}>
          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            classes={{
              dot:
                get(onlineUsers, `${user.username}.status`) === "online"
                  ? classes.success
                  : classes.error
            }}
            variant="dot"
          >
            <Avatar
              alt="user avatar"
              src={user.avatar}
              className={classes.avatar}
            />
          </StyledBadge>
        </Box>
      ) : (
        <Avatar
          alt="user avatar"
          srcSet={user.avatar}
          className={classes.avatar}
        />
      )}
    </div>
  );
};

CustomAvatar.defaultProps = {
  dotted: false
};

CustomAvatar.propTypes = {
  user: PropTypes.object,
  onlineUsers: PropTypes.object,
  dotted: PropTypes.bool
};

export default CustomAvatar;
