import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { submitLogin } from "../../redux/auth/thunks";
import { Container } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import CustomizedSnackbars from "../Chat/components/Snackbar";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://is-info.fr/">
        is-info.fr
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: "60px",
    height: "60px"
  },
  form: {
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 50px",
    height: "100%",
    textAlign: "center"
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%"
  },
  wrapper: {
    position: "relative",
    width: "60%"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -8,
    marginLeft: -12
  }
}));

const validate = values => {
  const errors = {};
  const requiredField = ["username", "password"];

  requiredField.forEach(field => {
    if (!values[field]) {
      errors[field] = "Required";
    }
  });
  return errors;
};

const renderField = ({ input, label, type, ...custom }) => (
  <TextField
    required
    type={type}
    label={label}
    {...input}
    {...custom}
    variant="outlined"
    margin="normal"
    size="small"
    id={type}
    name={type}
    color="secondary"
    style={{ width: "60%" }}
  />
);

renderField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string
};

let Login = props => {
  let history = useHistory();
  let location = useLocation();

  let snackBarRef = React.useRef();
  let [loading, setLoading] = React.useState(false);
  let [snackbarContent, setSnackbarContent] = React.useState({});

  let { from } = location.state || { from: { pathname: "/" } };

  const classes = useStyles();
  const { handleSubmit, submit } = props;

  async function login({ username, password }) {
    setLoading(true);
    let result = await submit({ username, password });
    if (result.success) {
      return history.replace(from);
    }

    setSnackbarContent(result);
    setLoading(false);
    snackBarRef.current.handleClick();
  }

  return (
    <Container component="div" maxWidth="xs" className={classes.root}>
      <Container main="div" style={{ marginRight: "50px" }}>
        <CustomizedSnackbars
          style={{
            zIndex: 999,
            position: "absolute",
            width: "100%"
          }}
          ref={snackBarRef}
          type={snackbarContent.type}
          message={snackbarContent.message}
        />
      </Container>
      <form onSubmit={handleSubmit(login)} className={classes.form}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon fontSize="large" />
        </Avatar>
        <Typography component="h1" variant="h3">
          Sign in
        </Typography>
        <Field
          name="username"
          component={renderField}
          type="text"
          label="Email Address"
          autoFocus
          autoComplete="email"
          disabled={loading}
        />
        <Field
          disabled={loading}
          name="password"
          component={renderField}
          type="password"
          label="password"
          autoComplete="current-password"
        />

        <div className={classes.wrapper}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.submit}
            disabled={loading}
          >
            Sign In
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
        {/* TODO */}
        {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" disabled>
                Forgot password?
              </Link>
            </Grid>
          </Grid> */}
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </Container>
  );
};

Login.propTypes = {
  handleSubmit: PropTypes.func,
  submit: PropTypes.func,
  submitting: PropTypes.bool
};
const mapStateToProps = ({ auth: { token }, ...props }) => ({
  ...props,
  token
});

const mapDispatchToProps = {
  submit: submitLogin
};

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

Login = reduxForm({
  form: "new-login",
  validate
})(Login);

export default Login;
