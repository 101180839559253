export const onStartUsersFetch = () => ({
  type: "onStartUsersFetch",
  isFething: true
});

export const onUsersFetch = users => ({
  type: "onUsersFetch",
  users
});

export const addMember = member => ({
  type: "onOnlineMember",
  member
});

export const removeMember = removedMember => ({
  type: "removedMember",
  removedMember
});

export const currentUsers = members => ({
  type: "currentUsers",
  members
});

export const onSearchChange = value => ({
  type: "onSearchChange",
  value
});

export const resetFetched = () => ({
  type: "resetFetched"
});
