import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles";
import clsx from "clsx";
import { Grid, CssBaseline, Hidden, Chip } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomAvatar from "../CustomAvatar";

const SideNavUser = ({
  user,
  selectedUser,
  lastMessage,
  onlineUsers,
  numberOfNewMessages
}) => {
  return (
    <div className={styles.container}>
      <Grid
        container
        component={NavLink}
        to={`/messaging/users/${user.id}`}
        className={clsx(
          styles.noUnderLine,
          selectedUser == user.id ? styles.selected : styles.userFriend
        )}
        direction="row"
        justify="flex-start"
      >
        <CssBaseline />
        <Grid item>
          <CustomAvatar user={user} onlineUsers={onlineUsers} dotted />
        </Grid>
        <Hidden smDown>
          <Grid item>
            <div className={styles.textContainer}>
              <span className={styles.name}>
                {user.firstname} {user.lastname}
              </span>

              <div className={styles.lastMessage}>
                {lastMessage
                  ? lastMessage.subject
                  : "hasn't sent you any message..."}
              </div>
            </div>
          </Grid>
        </Hidden>
        <Grid item className={styles.notificationContainer}>
          {numberOfNewMessages !== 0 && (
            <Chip
              color="primary"
              size="small"
              label={numberOfNewMessages}
              style={{
                backgroundColor: "#00e387",
                fontSize: "1.0em"
              }}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

SideNavUser.propTypes = {
  user: PropTypes.object,
  selectedUser: PropTypes.number,
  lastMessage: PropTypes.object,
  onlineUsers: PropTypes.object,
  numberOfNewMessages: PropTypes.number
};

export default SideNavUser;
