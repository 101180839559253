﻿import React from "react";
import styles from "./styles";
import clsx from "clsx";
import Login from "../../ChatApp/Login";
import { Button } from "@material-ui/core";

const Home = () => {
  let [slider, setSlider] = React.useState(false);

  return (
    <div
      className={clsx(styles.mainContainer, slider && styles.rightPanelActive)}
    >
      <div className={clsx(styles.container, styles.signUpContainer)}>
        <div className={styles.info}>
          <h1>Nothing here yet :)</h1>
        </div>
      </div>
      <div className={clsx(styles.container, styles.signInContainer)}>
        <Login />
      </div>
      <div className={styles.overlayContainer}>
        <div className={styles.overlay}>
          <div className={clsx(styles.overlayPanel, styles.overlayLeft)}>
            <h1>Curious ?</h1>
            <p className={styles.paragraph}>
              I will add some content here soon ;)
            </p>
            <Button
              style={{ color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={() => setSlider(false)}
            >
              Sign in
            </Button>
          </div>
          <div className={clsx(styles.overlayPanel, styles.overlayRight)}>
            <h1>Welcome to my site !</h1>
            <p className={styles.paragraph}>
              If you want to see who I am, click below
            </p>
            <Button
              style={{ color: "white", borderColor: "white" }}
              variant="outlined"
              onClick={() => setSlider(true)}
            >
              More Info
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
