import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { Field, reduxForm } from "redux-form";
import { TextField, Tooltip } from "@material-ui/core";
import PropTypes from "prop-types";

const renderField = ({ label, type, input, ...custom }) => (
  <TextField
    {...input}
    {...custom}
    required
    autoFocus
    margin="dense"
    id="outlined-required"
    label={label}
    type={type}
    fullWidth
  />
);

renderField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.object
};

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ControllerButton = withStyles({
  button: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)"
    }
  }
})(props => {
  const { onClick, classes } = props;
  return (
    <Tooltip title="Send invitation" placement="bottom" arrow>
      <IconButton size="small" className={classes.button} onClick={onClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0
  }
}))(MuiDialogActions);

class AddFriendDialog extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleSubmit, notificationResult, submitting } = this.props;
    return (
      <div>
        <ControllerButton onClick={this.handleClickOpen} />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            Add new friend
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              To add a new friend, enter his/her pseudo.
              <Field
                name="pseudoFriend"
                component={renderField}
                type="text"
                label="Friend Pseudo"
              />
              <span>{notificationResult}</span>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={submitting}>
                add friend
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </div>
    );
  }
}

AddFriendDialog.propTypes = {
  handleSubmit: PropTypes.func,
  notificationResult: PropTypes.string,
  submitting: PropTypes.bool
};

export default reduxForm({
  form: "AddFriendDialog"
})(AddFriendDialog);
