import React from "react";
import styles from "./styles";
import PropTypes from "prop-types";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";

class EmojiHolder extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate() {
    if (this.props && this.props.showEmojiPicker) {
      document.addEventListener("click", this.handleClickOutside);
    } else {
      document.removeEventListener("click", this.handleClickOutside);
    }
  }
  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onEmojiPicker(false);
    }
  }

  render() {
    const { showEmojiPicker, addEmoji } = this.props;
    return (
      <div className={styles.mainContainer} ref={this.setWrapperRef}>
        {showEmojiPicker && (
          <Picker set="google" onSelect={addEmoji} title="ChatApp" skin={1} />
        )}
      </div>
    );
  }
}

EmojiHolder.propTypes = {
  onEmojiPicker: PropTypes.func,
  showEmojiPicker: PropTypes.bool,
  addEmoji: PropTypes.func
};
export default EmojiHolder;
