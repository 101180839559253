import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import App from "./App";
import { Provider } from "react-redux";
import reduxStore from "./redux/reducers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import axios from "axios";
import { PersistGate } from "redux-persist/integration/react";

axios.defaults.baseURL = process.env.API_URL;
axios.defaults.headers.post["Content-Type"] = "application/json";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#273035"
    },
    secondary: {
      main: "#1769aa",
      secondary: "#2196f3"
    }
  },
  typography: {
    useNextVariants: true
  }
});

ReactDOM.render(
  <Provider store={reduxStore.store}>
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <PersistGate loading={null} persistor={reduxStore.persistor}>
          <Route component={App} />
        </PersistGate>
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("app-site")
);
