/* eslint-disable react/prop-types */
import React from "react";
import { Switch, Route } from "react-router-dom";
import { resetFetched } from "../../redux/users/actions";
import { connect } from "react-redux";
import { addNewMessageNotification } from "../../redux/messages/thunks";
import { rendMessage, confirmSending } from "../../redux/messages/actions";
import ChatSideNav from "./containers/ChatSideNav";
import Pusher from "pusher-js";
import { addNotification } from "../../redux/notifications/actions";
import { updateNotification } from "../../redux/notifications/thunks";
import Loader from "../../components/Loader";
import styles from "./styles";
import SideNavigation from "./containers/SideNavigation";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

const API_URL = process.env.API_URL;

Pusher.logToConsole = process.env.PUSHER_DEBUG === "true";

class Chat extends React.Component {
  state = {
    loading: true
  };
  authedUser = this.props.user.id;

  pusher = new Pusher("f4a1e52b6ee7ec38bd05", {
    cluster: "eu",
    forceTLS: true,
    authEndpoint: `${API_URL}/users/presence`,
    useTLS: true,
    auth: {
      headers: {
        Authorization: "Bearer " + this.props.token
      },
      params: {
        username: this.props.user.username,
        status: "online"
      }
    }
  });

  channel = this.pusher.subscribe("chat");

  componentDidMount() {
    const {
      rendMessage,
      confirmSending,
      addNotification,
      resetFetched,
      addNewMessageNotification,
      user,
      updateNotification
    } = this.props;

    this.channel.bind("message", function(data) {
      if (data.message.from_user === user.username) {
        return confirmSending(data.message);
      }
      addNewMessageNotification(data.message);
      return rendMessage(data.message, user.username);
    });

    this.channel.bind("notification", function(data) {
      if (data.invitationAccepted) {
        resetFetched();
        return updateNotification(data.notificationId);
      }

      if (
        user.pseudo === data.notification.from_user ||
        user.pseudo === data.notification.to_user
      ) {
        addNotification(data.notification);
      }
    });

    this.setState({ loading: false });
  }

  componentWillUnmount() {
    this.channel.unbind();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className={styles.background}>
          <div className={styles.loader}>
            <Loader /> ChatApp is loading...
          </div>
        </div>
      );
    }
    return (
      <Grid container component="main" direction="row">
        <Grid item className={styles.sideNavigation}>
          <SideNavigation />
        </Grid>
        <Switch>
          <Route
            path="/messaging"
            render={props => <ChatSideNav {...props} pusher={this.pusher} />}
          ></Route>
        </Switch>
      </Grid>
    );
  }
}

const mapState = ({ auth: { user, token } }) => ({
  user,
  token
});

const mapDispatch = {
  resetFetched,
  rendMessage,
  confirmSending,
  addNotification,
  updateNotification,
  addNewMessageNotification
};

Chat.propTypes = {
  user: PropTypes.object,
  token: PropTypes.string,
  match: PropTypes.object
};

export default connect(mapState, mapDispatch)(Chat);
