import * as actions from "./actions";
import { change, reset } from "redux-form";
import { logout } from "../auth/thunks";
import { onEmojiPicker } from "../chatControlers/actions";
import set from "lodash/set";
import axios from "axios";

export const fetchMessages = destUser => (dispatch, getState) => {
  const { messages, users } = getState();

  const nextUser = parseInt(destUser);
  if (messages.nextUser && messages.nextUser.id === nextUser) {
    return;
  }

  if (!users.isFetched) {
    return;
  }

  const user = Object.values(users.users).filter(user => {
    return user.id === nextUser;
  });

  dispatch(actions.onUserSelect(user[0]));

  console.info("fetching messages for user : ", nextUser);

  axios
    .post("/users/messages", { nextUser })
    .then(response => response.data)
    .then(results => {
      let newMessages = [];
      results.messages.map(message => {
        let messageWithSent = message;
        messageWithSent.sent = true;
        return newMessages.push(messageWithSent);
      });
      if (user[0]) {
        dispatch(actions.onFetchMessages(newMessages));
      }
    })
    .catch(() => dispatch(logout()));
};

export const sendMessage = message => (dispatch, getState) => {
  const { auth, messages } = getState();

  const newMessage = {
    id: null,
    messageId: `user${auth.user.id}:user${messages.nextUser.id}`,
    subject: message.message,
    date: new Date(),
    from_user: auth.user.username,
    to_user: messages.nextUser.username,
    sent: false
  };

  if (message.message && message.message !== "\n") {
    axios
      .post("/users/messages/send", { message: newMessage })
      .then(response => response.data)
      .then(response => {
        dispatch(actions.addMessage(response.message));
        dispatch(reset("message"));
      })
      .catch(() => dispatch(logout()));
  } else {
    dispatch(reset("message"));
  }
};

export const addEmoji = emoji => (dispatch, getState) => {
  const { form } = getState();

  const message =
    form.message.values !== undefined ? form.message.values.message : "";
  dispatch(change("message", "message", message + emoji.native));
  dispatch(onEmojiPicker(false));
};

export const getLastMessages = () => (dispatch, getState) => {
  const { users } = getState();

  dispatch(actions.isLastMessageFetching());

  axios
    .get("/users/messages/last")
    .then(response => response.data)
    .then(lastUserMessages => {
      lastUserMessages.lastUserMessages.map(m => {
        set(users.users[m.from_user], "lastMessage", m);
      });

      dispatch(actions.isLastMessageFetched());
    })
    .catch(() => console.error("Error : Unauthorized"));
};

export const addNewMessageNotification = message => (dispatch, getState) => {
  const { users, auth } = getState();
  if (message.to_user == auth.user.username) {
    set(users.users[message.from_user], "lastMessage", message);
    set(
      users.users[message.from_user],
      "numberOfNewMessages",
      (users.users[message.from_user]["numberOfNewMessages"] += 1)
    );
  }
};

export const resetNewMessageNumber = () => (dispatch, getState) => {
  const { users, messages } = getState();

  set(users.users[messages.nextUser.username], "numberOfNewMessages", 0);
};
