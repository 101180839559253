import React from "react";
import NavRoutes from "./NavRoutes";

class App extends React.Component {
  componentDidMount() {
    document.getElementById("app").style.display = "none";
  }

  render() {
    return (
      <React.Fragment>
        <NavRoutes />
      </React.Fragment>
    );
  }
}

export default App;
