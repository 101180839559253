import React from "react";
import set from "lodash/set";
import get from "lodash/get";
import keys from "lodash/keys";
import Users from "../Users";
import SettingsCenter from "../SettingsCenter";
import { Route, Switch } from "react-router-dom";
import Invitations from "../../components/Invitations";
import { connect } from "react-redux";
import {
  addMember,
  currentUsers,
  removeMember
} from "../../../../redux/users/actions";
import { logout } from "../../../../redux/auth/thunks";
import { fetchUsers } from "../../../../redux/users/thunks";
import { setInvitationsNumber } from "../../../../redux/notifications/actions";
import { confirmInvitation } from "../../../../redux/notifications/thunks";
import { sendMessage } from "../../../../redux/messages/thunks";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import styles from "./styles";
import ChatMain from "../ChatMain";

class ChatSideNav extends React.Component {
  componentDidMount() {
    const { currentUsers, addMember, removeMember, pusher } = this.props;
    let presenceChannel = pusher.subscribe("presence-chat");

    presenceChannel.bind("pusher:subscription_succeeded", function(members) {
      currentUsers(members.members);
    });

    presenceChannel.bind("pusher:member_added", function(member) {
      let newMember = set({}, get(member, "id"), get(member, "info"));
      addMember(newMember);
    });

    presenceChannel.bind("pusher:member_removed", function(member) {
      const removedMember = set({}, get(member, "id"), get(member, "info"));
      let normalizedRemovedUser = set({}, keys(removedMember)[0], "");
      removeMember(normalizedRemovedUser);
    });
  }

  render() {
    const {
      nextUser,
      notifications,
      user,
      fetchUsers,
      setInvitationsNumber,
      confirmInvitation,
      isLastMessagesFetched,
      sendMessage
    } = this.props;
    return (
      <React.Fragment>
        <Grid item className={styles.elementsContainer}>
          <Switch>
            <Route
              path="/messaging/invitations"
              render={props => (
                <Invitations
                  {...props}
                  notifications={notifications}
                  loggedUser={user}
                  fetchUsers={fetchUsers}
                  setInvitationsNumber={setInvitationsNumber}
                  confirmInvitation={confirmInvitation}
                />
              )}
            />
            <Route
              path="/messaging/settings"
              render={props => <SettingsCenter {...props} />}
            />
            <Route
              path="/messaging/users"
              render={props => (
                <Users
                  {...props}
                  selectedUser={nextUser.id}
                  isLastMessagesFetched={isLastMessagesFetched}
                />
              )}
            />
          </Switch>
        </Grid>
        <Switch>
          <Route
            path="/messaging/users/:id"
            render={props => <ChatMain {...props} onSubmit={sendMessage} />}
          ></Route>
        </Switch>
      </React.Fragment>
    );
  }
}

ChatSideNav.propTypes = {
  nextUser: PropTypes.object,
  notifications: PropTypes.array,
  user: PropTypes.object,
  fetchUsers: PropTypes.func,
  setInvitationsNumber: PropTypes.func,
  confirmInvitation: PropTypes.func,
  isLastMessagesFetched: PropTypes.bool,
  currentUsers: PropTypes.func,
  addMember: PropTypes.func,
  removeMember: PropTypes.func,
  sendMessage: PropTypes.func,
  pusher: PropTypes.object
};

const mapState = ({
  users: { onlineUsers, ...props },
  notifications: { notifications, invitationsNumber },
  messages: { isLastMessagesFetched, nextUser },
  auth: { user }
}) => ({
  ...props,
  user,
  nextUser,
  onlineUsers,
  notifications,
  invitationsNumber,
  isLastMessagesFetched
});

const mapDispatch = {
  sendMessage,
  addMember,
  currentUsers,
  removeMember,
  logout,
  fetchUsers,
  setInvitationsNumber,
  confirmInvitation
};
export default connect(mapState, mapDispatch)(ChatSideNav);
