import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import Chat from "./ChatApp/Chat";
import { submit } from "./redux/auth/thunks";
import Home from "./components/Home";

class NavRoutes extends React.Component {
  componentDidMount() {
    if (this.props.token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.token;
    }
  }

  componentDidUpdate() {
    let tokenParams = axios.defaults.headers.common["Authorization"];
    let token = tokenParams && tokenParams.split(" ")[1];

    if (this.props.token && this.props.token !== token) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.token;
    }
  }

  render() {
    const { token, reloadChatApp } = this.props;

    if (reloadChatApp) {
      sessionStorage.removeItem("persist:root");
      localStorage.removeItem("persist:root");
      return location.replace("/");
    }

    return (
      <Switch>
        <Route
          path="/home"
          render={({ location }) =>
            token ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            ) : (
              <Home />
            )
          }
        />
        <Route
          render={({ location, props }) =>
            token ? (
              <Chat {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/home",
                  state: { from: location }
                }}
              />
            )
          }
        />
      </Switch>
    );
  }
}
NavRoutes.propTypes = {
  token: PropTypes.string,
  reloadChatApp: PropTypes.bool
};

const mapStateToProps = ({
  auth: { token },
  chatControlers: { reloadChatApp },
  ...props
}) => ({
  ...props,
  token,
  reloadChatApp
});

const mapDispatchToProps = {
  submit
};

export default connect(mapStateToProps, mapDispatchToProps)(NavRoutes);
