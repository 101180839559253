import React from "react";
import styles from "./styles";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import PropTypes from "prop-types";

class Invitations extends React.Component {
  componentDidMount() {
    this.props.fetchUsers();
    this.props.setInvitationsNumber(0);
  }
  componentWillUnmount() {
    this.props.setInvitationsNumber(0);
  }
  render() {
    const { notifications, loggedUser, confirmInvitation } = this.props;
    return notifications.map((notification, key) =>
      notification.type === "invitation" &&
      notification.to_user === loggedUser.pseudo ? (
        <div className={styles.loggedUser} key={key}>
          {/* <img className={styles.userPhoto} src={props.user.avatar} alt="user" /> */}
          <div>
            <span className={styles.name}>{notification.from_user}</span>
            <span className={styles.status}>{notification.message}</span>
          </div>
          <div className={styles.acceptButton}>
            {notification.invitationAccepted ? (
              <i className={clsx("fa fa-check-circle", styles.checked)} />
            ) : (
              <Button
                className={styles.button}
                variant="contained"
                style={{
                  backgroundColor: "#1976d2",
                  color: "white"
                }}
                onClick={() => confirmInvitation(notification.from_user)}
              >
                accept
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.loggedUser} key={key}>
          {/* <img className={styles.userPhoto} src={props.user.avatar} alt="user" /> */}
          <div>
            <span className={styles.name}>{notification.to_user}</span>
            <span className={styles.status}>
              Has not accepted your invitation yet
            </span>
          </div>
          {notification.invitationAccepted && (
            <div className={styles.acceptButton}>
              <i className={clsx("fa fa-check-circle", styles.checked)} />
            </div>
          )}
        </div>
      )
    );
  }
}

Invitations.propTypes = {
  fetchUsers: PropTypes.func,
  setInvitationsNumber: PropTypes.func,
  notifications: PropTypes.array,
  loggedUser: PropTypes.object,
  confirmInvitation: PropTypes.func
};

export default Invitations;
