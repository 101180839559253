// The initial state

const initialState = {
  user: {
    id: null,
    firstname: null,
    lastname: null,
    type: null
  },
  token: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "onLoginUser":
      return { ...state, user: action.user, token: action.token };
    case "resetInitialState":
      return (state = initialState);
    default:
      return state;
  }
}
