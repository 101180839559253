import * as actions from "./actions";
import { logout } from "../auth/thunks";
import {
  setNotifications,
  setInvitationsNumber
} from "../notifications/actions";
import { getLastMessages } from "../messages/thunks";
import axios from "axios";

export const fetchUsers = () => (dispatch, getState) => {
  const { users, auth } = getState();

  if (users.isFetched) {
    console.info("Already fetched users and notifications");
    return;
  }
  dispatch(actions.onStartUsersFetch);

  console.info("Fetching users and notifications");

  axios
    .all([axios.get("/users"), axios.get("/users/notifications")])
    .then(
      axios.spread(function(users, notifications) {
        if (users.status === 200 && notifications.status === 200) {
          dispatch(actions.onUsersFetch(users.data.users));
          dispatch(getLastMessages());

          let notifs = notifications.data.notifications.map(notif => {
            notif.invitationAccepted = false;
            return notif;
          });

          dispatch(setNotifications(notifs));

          let countInnvitations = 0;

          notifications.data.notifications.map(notif => {
            if (
              notif.type == "invitation" &&
              notif.to_user == auth.user.pseudo
            ) {
              countInnvitations += 1;
            }
            dispatch(setInvitationsNumber(countInnvitations));
          });
        }
      })
    )
    .catch(err => dispatch(logout()));
};

export const uploadFile = file => {
  const formData = new FormData();

  formData.append("photo", file[0], file[0].name);

  axios.post("/users/upload", formData).then(response => console.log(response));
};
