const initialState = {
  users: [],
  isFetched: false,
  isFetching: false,
  search: "",
  onlineUsers: {}
};

// Users reducer

export default function(state = initialState, action) {
  switch (action.type) {
    case "onStartUsersFetch":
      return { ...state, isFetching: action.isFetching };
    case "currentUsers":
      return {
        ...state,
        onlineUsers: action.members
      };
    case "onOnlineMember":
      return {
        ...state,
        onlineUsers: { ...state.onlineUsers, ...action.member }
      };
    case "resetFetched":
      return {
        ...state,
        isFetched: false
      };
    case "removedMember":
      return {
        ...state,
        onlineUsers: { ...state.onlineUsers, ...action.removedMember }
      };
    case "onUsersFetch":
      return {
        ...state,
        isFetched: true,
        isFetching: false,
        users: action.users
      };
    case "resetInitialState":
      return (state = initialState);
    case "onSearchChange":
      return { ...state, search: action.value };
    default:
      return state;
  }
}
