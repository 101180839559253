const initialState = {
  signInMessage: "",
  notifications: [],
  requestResult: "",
  invitationsNumber: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case "setSignInMessage":
      return { ...state, signInMessage: action.value };
    case "setNotifications":
      return { ...state, notifications: action.value };
    case "getResultMessage":
      return { ...state, requestResult: action.value };
    case "setInvitationsNumber":
      return { ...state, invitationsNumber: action.value };
    case "addNotification":
      return {
        ...state,
        notifications: [...state.notifications, action.value],
        invitationsNumber: state.invitationsNumber + 1
      };
    default:
      return state;
  }
}
