import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import users from "./users";
import messages from "./messages";
import auth from "./auth";
import notifications from "./notifications";
import chatControlers from "./chatControlers";
import { reducer as formReducer } from "redux-form";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const rootReducer = combineReducers({
  users,
  messages,
  auth,
  notifications,
  chatControlers,
  form: formReducer
});

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["auth"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore() {
  // Enhancers for developpement purpose
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // The store
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
}

let store = configureStore();
let persistor = persistStore(store);

export default {
  store,
  persistor
};
