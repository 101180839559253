export const onUserSelect = nextUser => ({
  type: "onUserSelect",
  nextUser
});

export const onFetchMessages = usersMessages => ({
  type: "onFetchMessages",
  usersMessages
});

export const rendMessage = (message, user) => ({
  type: "rendMessage",
  data: {
    message: message,
    user: user
  }
});

export const addMessage = message => ({
  type: "addMessage",
  message
});

export const addFile = file => ({
  type: "addFile",
  file
});

export const confirmSending = message => ({
  type: "confirmSending",
  message
});

export const isLastMessageFetching = () => ({
  type: "isLastMessageFetching"
});

export const isLastMessageFetched = () => ({
  type: "isLastMessageFetched"
});

export const setNextUserId = id => ({
  type: "setNextUserId",
  id
});

export const resetMessagesReducer = () => ({
  type: "resetState"
});
